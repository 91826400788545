import { SessionConcernType } from "@generated/graphql";
import clsx from "clsx";
import {
  Button,
  Icon,
  SelectMenu,
  SelectMenuOption,
  TextArea,
} from "components/shared";
import { ReactNode, useMemo } from "react";
import { cellStyles, concernTypeOptions } from "../../../../../../constants";
import { SessionConcern } from "../../../../../../types";
import { CONCERN_TYPE_W, DETAILS_MIN_W, STUDENT_W } from "../constants";

type Props = {
  zIndex: number;
  readOnly: boolean;
  isLastRow: boolean;
  className?: string;
  concern: SessionConcern;
  studentOptions: SelectMenuOption[];
  addConcern?: () => void;
  removeConcern?: () => void;
  editConcern: (concern: SessionConcern) => void;
};

export const ConcernSectionRow = ({
  zIndex,
  concern,
  readOnly,
  isLastRow,
  className,
  studentOptions,
  addConcern,
  removeConcern,
  editConcern,
}: Props) => {
  const isDraftRow = !!addConcern;
  const { concernNotes } = concern;
  const noStudent = concern.studentId === "none";
  const noConcernType = concern.concernType === null;
  const noDetails = !concernNotes || concernNotes === "";
  const disabled = noConcernType || noStudent || noDetails;

  const { filteredConcernTypeOptions, concernIndex } = useMemo(() => {
    const filteredConcernTypeOptions = isDraftRow
      ? concernTypeOptions
      : concernTypeOptions.slice(1);

    return {
      filteredConcernTypeOptions,
      concernIndex: filteredConcernTypeOptions.findIndex(
        (c) => c.concern === concern.concernType
      ),
    };
  }, [concern.concernType, isDraftRow]);

  const { filteredStudentOptions, studentIndex } = useMemo(() => {
    const filteredStudentOptions = isDraftRow
      ? studentOptions
      : studentOptions.slice(1);

    let studentIndex = filteredStudentOptions.findIndex(
      ({ id }) => id === concern.studentId
    );

    // Non Student Option Mapping
    if (concern.studentId === "none") studentIndex = 0;
    if (concern.studentId === null) studentIndex = isDraftRow ? 1 : 0;

    return {
      filteredStudentOptions,
      studentIndex,
    };
  }, [isDraftRow, studentOptions, concern.studentId]);

  const getReadOnlyNote = (note: ReactNode) => (
    <div className="flex text-sm font-medium text-gray-600">{note}</div>
  );

  return (
    <div
      style={{ zIndex }}
      className={clsx(
        "flex w-full min-h-[42px] h-auto ",
        isDraftRow ? "bg-gray-100/80" : "bg-white",
        isLastRow && "rounded-b-lg",
        className
      )}
    >
      <div
        className={clsx(cellStyles)}
        style={{ width: `${CONCERN_TYPE_W}px` }}
      >
        {readOnly ? (
          getReadOnlyNote(filteredConcernTypeOptions[concernIndex].value)
        ) : (
          <SelectMenu
            required
            className="w-full"
            initialIndex={concernIndex}
            options={filteredConcernTypeOptions}
            onSelect={(c) =>
              editConcern({
                ...concern,
                concernType:
                  c.id === "NONE" ? null : (c.concern as SessionConcernType),
              })
            }
          />
        )}
      </div>

      <div className={clsx(cellStyles)} style={{ width: `${STUDENT_W}px` }}>
        {readOnly ? (
          getReadOnlyNote(filteredStudentOptions[studentIndex].value)
        ) : (
          <SelectMenu
            required
            className="w-full"
            initialIndex={studentIndex}
            options={filteredStudentOptions}
            onSelect={({ id }) =>
              editConcern({
                ...concern,
                studentId:
                  id === "GENERAL" ? null : id === "NONE" ? "none" : id,
              })
            }
          />
        )}
      </div>

      <div
        className={clsx("flex-1", cellStyles)}
        style={{ minWidth: `${DETAILS_MIN_W}px` }}
      >
        {readOnly ? (
          getReadOnlyNote(concern.concernNotes)
        ) : (
          <>
            <TextArea
              rows={1}
              id="notes"
              className="w-full"
              textAreaClassName="min-h-[38px]"
              value={concern.concernNotes ?? ""}
              onChange={(e) =>
                editConcern({ ...concern, concernNotes: e.target.value })
              }
            />
            <Button
              disabled={disabled}
              theme={isDraftRow ? "primary" : "danger"}
              className={clsx("px-3! ml-2", disabled && "cursor-default!")}
              onClick={() => (isDraftRow ? addConcern() : removeConcern?.())}
            >
              <Icon
                icon={isDraftRow ? "plus" : "minus"}
                size={4}
                color="text-white"
              />
            </Button>
          </>
        )}
      </div>
    </div>
  );
};
