import { gql } from "@apollo/client";
import {
  CohortAssignmentSubject,
  CohortSessionStudentAttendanceStatus,
  GetIsEvaluationComplete_TutorDashboardCohortSessionStudentAttendanceFragment,
  GetIsEvaluationComplete_TutorDashboardCohortSessionStudentGradingFragment,
} from "@generated/graphql";
import {
  elaSubjectGradeOptions,
  GRADE_TYPE_TO_ABSENT_TYPE_MAP,
  nonElaSubjectGradeOptions,
} from "components/shared/AttendanceGrades/constants";

getIsEvaluationComplete.fragments = {
  tutorDashboardCohortSessionStudentAttendance: gql`
    fragment GetIsEvaluationComplete_TutorDashboardCohortSessionStudentAttendance on TutorDashboardCohortSessionStudentAttendance {
      id
      status
    }
  `,
  tutorDashboardCohortSessionStudentGrading: gql`
    fragment GetIsEvaluationComplete_TutorDashboardCohortSessionStudentGrading on TutorDashboardCohortSessionStudentGrading {
      id
      readingGrade
      classworkGrade
      languageArtsGrade
      participationGrade
      readingAbsentFromAssessment
      classworkAbsentFromAssessment
      languageArtsAbsentFromAssessment
    }
  `,
};

export function getIsEvaluationComplete(
  gradingEnabled: boolean,
  subject: CohortAssignmentSubject,
  attendance?: GetIsEvaluationComplete_TutorDashboardCohortSessionStudentAttendanceFragment | null,
  grading?: GetIsEvaluationComplete_TutorDashboardCohortSessionStudentGradingFragment | null
) {
  const { Absent, Unknown } = CohortSessionStudentAttendanceStatus;
  if (!attendance || (gradingEnabled && !grading)) return false;
  if (attendance.status === Absent) return true;

  const attendanceIsTaken = attendance.status !== Unknown;

  let gradingIsTaken = !gradingEnabled;

  if (gradingEnabled && grading) {
    const isELA = subject === CohortAssignmentSubject.Ela;
    const gradeOptions = isELA
      ? elaSubjectGradeOptions
      : nonElaSubjectGradeOptions;

    gradingIsTaken = gradeOptions.every((gradeType) => {
      const absentKey = GRADE_TYPE_TO_ABSENT_TYPE_MAP[gradeType];
      const hasGrade = grading[gradeType] !== null;
      return absentKey === null ? hasGrade : hasGrade || grading[absentKey];
    });
  }

  return attendanceIsTaken && gradingIsTaken;
}
