import { ProficiencyRating } from "@generated/graphql";
import { AttendanceStatusPillButton } from "@shared/AttendanceGrades/components";
import { ThreePointScaleInput } from "@shared/Inputs";
import { SessionReportStudentEvaluationRow } from "@shared/SessionReports/types";
import { Routes } from "@utils/routes";
import clsx from "clsx";
import { Link, TextArea } from "components/shared";
import { useLayout } from "contexts/LayoutProvider";
import { StudentPerformance } from "../../../../../../types";
import {
  ATTENDANCE_W,
  cellStyles,
  ENGAGEMENT_READONLY_W,
  ENGAGEMENT_W,
  MASTERY_READONLY_W,
  MASTERY_W,
  NAME_W,
  PERFORMANCE_MIN_W,
} from "../constants";

type Props = {
  zIndex: number;
  readOnly: boolean;
  isLastRow: boolean;
  isMasteryRequired: boolean;
  highlightIncomplete: boolean;
  studentPerformance: StudentPerformance;
  studentEvaluation: SessionReportStudentEvaluationRow;
  updateData: (studentId: string, data: Partial<StudentPerformance>) => void;
};

export const StudentPerformanceTableRow = ({
  zIndex,
  readOnly,
  isLastRow,
  isMasteryRequired,
  studentPerformance,
  highlightIncomplete,
  studentEvaluation: { attendance, fullName, studentId },
  updateData,
}: Props) => {
  const { isLessThan3XlScreen } = useLayout();
  const { mastery, engagement } = studentPerformance || {};
  const mergeNotesColumn = isLessThan3XlScreen && !readOnly;

  const validMastery = !!mastery || !isMasteryRequired;
  const showError = highlightIncomplete && (!validMastery || !engagement);

  const textArea = readOnly ? (
    <div className="flex-1 w-full min-w-[350px] px-4 py-2 leading-4 text-sm">
      {studentPerformance?.shoutout ? (
        studentPerformance?.shoutout
      ) : (
        <div className="text-sm text-gray-300">No shout outs...</div>
      )}
    </div>
  ) : (
    <TextArea
      id="shoutout"
      textAreaClassName="min-h-[38px]"
      value={studentPerformance?.shoutout ?? ""}
      rows={!!studentPerformance?.shoutout ? 2 : 1}
      placeholder="Give a shout out! Highlight your students positive performance here."
      className={clsx(
        "flex-1 w-full min-w-[350px]",
        mergeNotesColumn ? "py-0 pl-4" : "p-1 pl-3"
      )}
      onChange={(e) => updateData(studentId, { shoutout: e.target.value })}
    />
  );

  const getProficiencyColumn = (
    value: ProficiencyRating | null,
    valueName: string,
    yesNoSomewhatTextOutput = false
  ) => (
    <div
      className={clsx(
        cellStyles,
        mergeNotesColumn ? "pt-0 pb-1" : "py-1 justify-center"
      )}
      style={{
        width: mergeNotesColumn
          ? "50%"
          : `${
              readOnly
                ? yesNoSomewhatTextOutput
                  ? ENGAGEMENT_READONLY_W
                  : MASTERY_READONLY_W
                : yesNoSomewhatTextOutput
                ? ENGAGEMENT_W
                : MASTERY_W
            }px`,
      }}
    >
      <ThreePointScaleInput
        readOnly={readOnly}
        value={value ?? null}
        yesNoSomewhatTextOutput={yesNoSomewhatTextOutput}
        required={yesNoSomewhatTextOutput || isMasteryRequired}
        onChange={(value) => updateData(studentId, { [valueName]: value })}
      />
    </div>
  );

  const lastCols = (
    <>
      {getProficiencyColumn(mastery, "mastery")}
      {getProficiencyColumn(engagement, "engagement", true)}
      {!mergeNotesColumn && textArea}
    </>
  );

  return attendance?.status ? (
    <div
      style={{ zIndex }}
      className={clsx(
        "flex w-full min-h-[38px] bg-white items-center",
        isLastRow && "rounded-b-lg",
        showError && "bg-rose-100!"
      )}
    >
      {/* Student Column */}
      <div
        className={clsx(cellStyles, "font-semibold text-sm text-slate-600")}
        style={{ width: `${NAME_W}px` }}
      >
        <Link
          route={Routes.engagementsDashboard}
          routeProps={[`search=${fullName}`]}
          className="flex flex-wrap leading-5"
        >
          {fullName}
        </Link>
      </div>

      {/* Attendance Column */}
      <div
        className={clsx(cellStyles, "justify-center")}
        style={{ width: `${ATTENDANCE_W}px` }}
      >
        <AttendanceStatusPillButton status={attendance?.status} />
      </div>

      {/* Scale & Shoutouts Columns */}
      {mergeNotesColumn ? (
        <div
          className={clsx(cellStyles, "flex-1 grow p-0 flex-col")}
          style={{ width: `${PERFORMANCE_MIN_W}px` }}
        >
          <div className="flex items-center w-full">{lastCols}</div>
          {textArea}
        </div>
      ) : (
        lastCols
      )}
    </div>
  ) : null;
};
