import { darkButtonStyles } from "@utils/styleStrings";
import clsx from "clsx";
import { Icon } from "../../Icon";

export enum ReportSubmitLocation {
  TutorDashboard = "Tutor Dashboard",
  EngagementDashboard = "Engagement Dashboard",
}

type Props = {
  className?: string;
  hasReport: boolean;
  sessionHasStarted: boolean;
  showButtonAsIcon: boolean;
  submitLocation: ReportSubmitLocation;
};

export const ReportSubmitButton = ({
  hasReport,
  className,
  submitLocation,
  sessionHasStarted,
  showButtonAsIcon,
}: Props) => {
  const isTTDash = submitLocation === ReportSubmitLocation.TutorDashboard;

  return (
    <div
      className={clsx(
        "flex flex-center h-full! gap-x-[6px] min-h-7 rounded-md relative",
        "cursor-pointer",
        hasReport
          ? "bg-emerald-600 hover:bg-emerald-700"
          : !sessionHasStarted
          ? "bg-gray-300"
          : "bg-rose-500 hover:bg-rose-600",
        darkButtonStyles,
        isTTDash && !showButtonAsIcon ? "pl-3 pr-4 w-fit!" : "w-9!",
        !sessionHasStarted ? "text-slate-600!" : "text-white!",
        className
      )}
    >
      <Icon
        size={hasReport ? (isTTDash ? 5 : 6) : 5}
        className={clsx(!sessionHasStarted ? "text-slate-600" : "text-white")}
        icon={hasReport ? "report" : !sessionHasStarted ? "timer" : "submit"}
      />
      {!showButtonAsIcon && isTTDash ? (
        <div className="whitespace-nowrap">Session Report</div>
      ) : null}
    </div>
  );
};
