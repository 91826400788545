import { SessionStudentEvaluationTable } from "@shared/AttendanceGrades/components";
import {
  SessionReportEvaluationTableDetails,
  SessionReportStudentEvaluationRow,
} from "@shared/SessionReports/types";
import { oxfordCommaList } from "@utils/strings";
import { isLiveParticipantsVideoProvider } from "@utils/videoProviders";
import { clsx } from "clsx";
import { Icon, Modal, Tooltip } from "components/shared";
import { useState } from "react";
import { SessionModalPageHeader } from "../../helpers";

type Props = {
  readOnly: boolean;
  allEvaluationsComplete: boolean;
  evaluationDetails: SessionReportEvaluationTableDetails;
  studentEvaluationRows: SessionReportStudentEvaluationRow[];
  onClose: () => void;
  onConfirm: () => void;
};

export const StudentEvaluationModalBody = ({
  readOnly,
  evaluationDetails,
  studentEvaluationRows,
  allEvaluationsComplete,
  onClose,
  onConfirm,
}: Props) => {
  const [highlightIncomplete, setHighlightIncomplete] = useState(false);

  const instructions =
    oxfordCommaList(
      "Please ensure all attendance is complete",
      isLiveParticipantsVideoProvider(evaluationDetails.videoProvider) &&
        "names are matched",
      evaluationDetails.isGradingEnabled && "grades are input"
    ) + ".";

  return (
    <div className="flex flex-col gap-y-5">
      {!readOnly && (
        <SessionModalPageHeader
          title="Student Attendance"
          description={instructions}
        />
      )}

      <SessionStudentEvaluationTable
        readOnly={readOnly}
        evaluationDetails={evaluationDetails}
        highlightIncomplete={highlightIncomplete}
        studentEvaluationRows={studentEvaluationRows}
      />

      {!readOnly && (
        <Modal.Buttons className="flex items-end mt-1!">
          <Tooltip
            disabled={allEvaluationsComplete}
            tooltipProps={{ place: "top-start" }}
            content={
              <div
                className={clsx(
                  "flex flex-col items-center text-center justify-center w-[370px] leading-5"
                )}
              >
                <span className="text-base">
                  Complete all student evaluations to proceed.
                </span>
                <span>
                  {`Ensure that all student's attendance (and grading if applicable) is marked, and their names are matched.`}
                </span>
              </div>
            }
          >
            <div
              className="flex flex-col gap-y-1 items-center cursor-pointer"
              onMouseEnter={() => setHighlightIncomplete(true)}
              onMouseLeave={() => setHighlightIncomplete(false)}
            >
              <span
                className={clsx(
                  "text-2xs font-bold",
                  allEvaluationsComplete ? "text-gray-800" : "text-rose-700"
                )}
              >
                Next: Tutor Notes
              </span>
              <Modal.Button
                type="confirm"
                onClick={onConfirm}
                disabled={!allEvaluationsComplete}
              >
                <div className="flex items-center gap-x-2 px-1">
                  Continue
                  <Icon
                    icon="chevronRight"
                    color="text-white"
                    size={6}
                    className="-ml-[4px] -mr-[9px]"
                  />
                </div>
              </Modal.Button>
            </div>
          </Tooltip>
          <Modal.Button type="cancel" onClick={onClose}>
            {readOnly ? "Close" : "Cancel"}
          </Modal.Button>
        </Modal.Buttons>
      )}
    </div>
  );
};
