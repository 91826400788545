import { triggerSuccessToast } from "components/shared";

export const successToast = (toastDetails: { message: string; sub: string }) =>
  triggerSuccessToast(toastDetails);

export const addedToast = (fetchEntity: string, fn?: () => void) => {
  successToast({
    message: `${fetchEntity} Added`,
    sub: `${fetchEntity} has successfully been added!`,
  });
  fn?.();
};

export const savedToast = (fetchEntity: string, fn?: () => void) => {
  successToast({
    message: `${fetchEntity} Saved`,
    sub: `${fetchEntity} has successfully been saved!`,
  });
  fn?.();
};

export const editedToast = (fetchEntity: string, fn?: () => void) => {
  successToast({
    message: `${fetchEntity} Updated`,
    sub: `${fetchEntity} has successfully been edited!`,
  });
  fn?.();
};

export const deletedToast = (fetchEntity: string, fn?: () => void) => {
  successToast({
    message: `${fetchEntity} Deleted`,
    sub: `${fetchEntity} has successfully been removed!`,
  });
  fn?.();
};
