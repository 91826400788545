import { useLayout } from "@contexts/LayoutProvider";
import clsx from "clsx";
import { useEffect, useRef, useState } from "react";
import { Icon } from "../../Icon";
import { Tooltip } from "../../Tooltip";
import { SessionReportModal } from "../SessionReportModal/SessionReportModal";
import { ReportSubmitButton, ReportSubmitLocation } from "./ReportSubmitButton";

type Props = {
  className?: string;
  hasConcerns: boolean;
  hideButton?: boolean;
  showButtonAsOnlyIcon?: boolean;
  cohortSessionStartTime: number;
  cohortSessionId?: string | null;
  hideUntilSessionStarts?: boolean;
  cohortSessionReportId?: string | null;
  submitLocation?: ReportSubmitLocation | null;
  refetch?: () => void;
};

export const OpenSessionReportButton = ({
  className,
  hasConcerns,
  submitLocation,
  cohortSessionId,
  hideButton = false,
  cohortSessionReportId,
  cohortSessionStartTime,
  showButtonAsOnlyIcon = false,
  hideUntilSessionStarts = false,
  refetch,
}: Props) => {
  const isSubmitButton = !!submitLocation;
  const { isLessThanXlScreen } = useLayout();
  const [showModal, setShowModal] = useState(false);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const [sessionHasStarted, setSessionHasStarted] = useState(false);
  const showButtonAsIcon = isLessThanXlScreen || showButtonAsOnlyIcon;

  useEffect(() => {
    if (sessionHasStarted) return;

    if (cohortSessionStartTime > 0 && cohortSessionStartTime <= Date.now()) {
      setSessionHasStarted(true);
    } else if (cohortSessionStartTime > 0) {
      timeoutRef.current = setTimeout(
        () => setSessionHasStarted(true),
        cohortSessionStartTime - Date.now()
      );
    }

    return () => {
      timeoutRef.current && clearTimeout(timeoutRef.current);
    };
  }, [setSessionHasStarted, cohortSessionStartTime, sessionHasStarted]);

  const hasSession = !!cohortSessionId;
  const hasReport = !!cohortSessionReportId;
  const canSubmitReport = hasSession && isSubmitButton && sessionHasStarted;

  return (hideUntilSessionStarts && !sessionHasStarted) || hideButton ? null : (
    <>
      <div
        className="flex flex-center h-full! w-fit"
        onClick={(e) => {
          e.stopPropagation();
          if (hasReport || canSubmitReport) setShowModal(true);
        }}
      >
        <Tooltip
          tooltipProps={{ place: "left" }}
          className="relative flex gap-x-1.5 h-full!"
          disabled={canSubmitReport && !showButtonAsIcon}
          content={
            <div className="max-w-[480px] text-center leading-none text-sm text-white font-semibold">
              {hasReport ? (
                "View Session Report"
              ) : canSubmitReport ? (
                "Submit Session Report"
              ) : (
                <>
                  <div className="text-rose-300 font-bold">
                    No Session Report Submitted
                  </div>
                  <div className="text-sm leading-none mt-px">
                    {sessionHasStarted
                      ? `This session's TT has not yet submitted this session report.`
                      : `Session reports can only be submitted once they have started.`}
                  </div>
                </>
              )}
              {hasConcerns && (
                <div className="font-xs mt-px text-rose-300">
                  This report has session concerns.
                </div>
              )}
            </div>
          }
        >
          {isSubmitButton ? (
            <ReportSubmitButton
              hasReport={hasReport}
              submitLocation={submitLocation}
              showButtonAsIcon={showButtonAsIcon}
              sessionHasStarted={sessionHasStarted}
            />
          ) : (
            <>
              <Icon
                size={6}
                icon="report"
                className={className}
                color={clsx(
                  hasReport
                    ? "text-emerald-500"
                    : canSubmitReport
                    ? "text-rose-500"
                    : "text-gray-400"
                )}
              />
            </>
          )}
          <div
            className={clsx(
              "absolute rounded-full",
              isSubmitButton
                ? "-top-[3px] -right-[3px] h-[10px] w-[10px]"
                : "top-[1px] right-[3px] h-2 w-2",
              hasConcerns ? "bg-rose-700/85" : "bg-white/0"
            )}
          />
        </Tooltip>
      </div>

      <SessionReportModal
        show={showModal}
        readOnly={!isSubmitButton}
        cohortSessionId={cohortSessionId ?? ""}
        sessionReportId={cohortSessionReportId ?? null}
        onClose={() => {
          refetch?.();
          setShowModal(false);
        }}
      />
    </>
  );
};
