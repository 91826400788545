import {
  CohortAssignmentSubject,
  CohortSessionStudentAttendanceStatus,
  SessionConcernType,
  SessionReportInstructionType,
  SessionReportModal_SessionReportModalDataFragment,
} from "@generated/graphql";
import { SessionReportStudentEvaluationRow } from "../types";
import { getIsEvaluationComplete } from "../utils";
import {
  FlsLevel,
  InstructionData,
  Off2ClassLesson,
  SessionConcernData,
  StudentPerformanceMap,
  UfliUnit,
  VIMElaPriorityStandards,
  VIMMathStrands,
  WIMMathLearningTargets,
} from "./types";

export const syncModalData = (
  setInstructionData: (data: InstructionData) => void,
  setConcernsData: (data: SessionConcernData) => void,
  setStudentPerformanceMap: (data: StudentPerformanceMap) => void,
  csr: SessionReportModal_SessionReportModalDataFragment["sessionReport"] | null
) => {
  if (csr) {
    const { sessionConcerns, studentPerformance, learningTarget } = csr;
    setInstructionData({
      classCode: csr.classCode ?? null,
      flsLesson: csr.flsLesson ?? null,
      ufliLesson: csr.ufliLesson ?? null,
      flsSkillCycle: csr.flsSkillCycle ?? null,
      flsLevel: (csr.flsLevel as FlsLevel) ?? null,
      ufliUnit: (csr.ufliUnit as UfliUnit) ?? null,
      strand: (csr.strand as VIMMathStrands) ?? null,
      instructionNotes: csr.instructionNotes ?? null,
      off2ClassLesson: (csr.off2ClassLesson as Off2ClassLesson) ?? null,
      learningTarget: (learningTarget as WIMMathLearningTargets) ?? null,
      priorityStandard:
        (csr.priorityStandard as VIMElaPriorityStandards) ?? null,
    });
    setConcernsData({
      hasConcerns: sessionConcerns.length > 0,
      concerns: sessionConcerns.map((sc) => ({
        concernType: sc.concernType ?? null,
        concernNotes: sc.concernNotes ?? null,
        studentId: sc.studentId ? String(sc.studentId) : null,
      })),
    });
    const performanceMap = {} as StudentPerformanceMap;
    studentPerformance.forEach((sp) => {
      performanceMap[sp.studentId] = {
        mastery: sp.mastery ?? null,
        shoutout: sp.shoutout ?? null,
        engagement: sp.engagement ?? null,
      };
    });
    setStudentPerformanceMap(performanceMap);
  }
};

export const isInstructionDataValid = (
  instructionData: InstructionData,
  instructionType?: SessionReportInstructionType | null
): boolean => {
  const { strand, learningTarget, instructionNotes } = instructionData;
  const type = instructionType ?? SessionReportInstructionType.Default;

  switch (type) {
    case SessionReportInstructionType.VimMath:
      return !!learningTarget && !!strand;
    case SessionReportInstructionType.VimEla:
      return !!instructionData.priorityStandard;
    case SessionReportInstructionType.Vsup:
      return !!instructionData.classCode;
    case SessionReportInstructionType.Off2Class:
      return !!instructionData.off2ClassLesson;
    case SessionReportInstructionType.Ufli:
      return (
        !!instructionData.ufliUnit &&
        (instructionData.ufliUnit === UfliUnit.FirstDayActivity ||
          !!instructionData.ufliLesson)
      );
    case SessionReportInstructionType.Fls:
      return (
        !!instructionData.flsLevel &&
        (instructionData.flsLevel === UfliUnit.FirstDayActivity ||
          (!!instructionData.flsSkillCycle && !!instructionData.flsLesson))
      );
    case SessionReportInstructionType.Default:
      return !!instructionNotes && instructionNotes !== "";
  }
};

export const isStudentPerformanceValid = (
  masteryIsRequired: boolean,
  studentPerformance: StudentPerformanceMap,
  attendedStudents: SessionReportStudentEvaluationRow[]
): boolean =>
  attendedStudents.every(
    (s) =>
      !!studentPerformance[s.studentId] &&
      !!studentPerformance[s.studentId].engagement &&
      (!!studentPerformance[s.studentId].mastery || !masteryIsRequired)
  );

export const getSaveData = (
  sessionReportId: string | null,
  cohortSessionId: string,
  instructionData: InstructionData,
  concernsData: SessionConcernData,
  attendedStudents: SessionReportStudentEvaluationRow[],
  studentPerformanceMap: StudentPerformanceMap
) => ({
  input: {
    id: sessionReportId,
    cohortSessionId,
    ...instructionData,
    sessionConcerns:
      concernsData.hasConcerns && (concernsData.concerns ?? []).length > 0
        ? (concernsData.concerns ?? []).map((c) => ({
            studentId: c.studentId ?? null,
            concernNotes: c.concernNotes ?? "",
            concernType: c.concernType ?? SessionConcernType.Other,
          }))
        : [],
    studentPerformance: attendedStudents.map(({ studentId }) => {
      const studentPerformance = studentPerformanceMap[studentId];
      const { engagement, shoutout } = studentPerformance;

      return {
        studentId,
        shoutout: shoutout ?? null,
        engagement: engagement ?? null,
        mastery: studentPerformance.mastery ?? null,
      };
    }),
  },
});

export const getStudentData = (
  studentRows?: SessionReportModal_SessionReportModalDataFragment["studentRows"],
  evaluationDetails?: SessionReportModal_SessionReportModalDataFragment["evaluationDetails"],
  sessionDetails?: SessionReportModal_SessionReportModalDataFragment["sessionDetails"]
) => {
  const subject = sessionDetails?.subject ?? CohortAssignmentSubject.General;
  const studentEvaluationRows = (studentRows ?? []).map((row) => ({
    ...row,
    isEvaluationComplete: getIsEvaluationComplete(
      evaluationDetails?.isGradingEnabled ?? false,
      subject,
      row.attendance,
      row.grading
    ),
  }));

  return {
    studentEvaluationRows,
    attendedStudents: studentEvaluationRows.filter(
      ({ attendance }) =>
        attendance?.status &&
        attendance.status !== CohortSessionStudentAttendanceStatus.Absent &&
        attendance.status !== CohortSessionStudentAttendanceStatus.Unknown
    ),
  };
};

export const getSessionButtonStyles = (submitted: boolean) =>
  submitted
    ? "bg-emerald-500 hover:bg-emerald-600 group-hover:bg-emerald-600"
    : "bg-rose-500 hover:bg-rose-600 group-hover:bg-rose-600";

export const getNumberSequenceStrings = (
  start: number,
  end: number
): string[] =>
  [...new Array(end - start + 1)].map((__, i) => String(i + start));
